import React from "react";
import "./UnderDev.css";

// --------------------------------------------------------------------------------------------------------------------------------
// Under Development Page 
// --------------------------------------------------------------------------------------------------------------------------------

function UnderDev() {
    return (
        <div className="underdev">
            <h1>Sorry, this page is still under Development..</h1>
        </div>
    )
}

export default UnderDev;